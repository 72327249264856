/* eslint-disable react/jsx-props-no-spreading */
import { FormattedMessage, useIntl } from 'react-intl';
import * as styles from './Post.module.scss';

const Post = ({ title, children, dangerousHTML }) => {
    const { formatMessage } = useIntl();
    return (
        <>
            <h4 className={styles.title}>
                {typeof title === 'string' && title && (
                    title
                )}
                {typeof title !== 'string' && title && (
                    <FormattedMessage {...title} />
                )}
            </h4>
            <p className={styles.post}>
                {children && children}
                {dangerousHTML
             && <div dangerouslySetInnerHTML={{ __html: formatMessage(dangerousHTML) }} />}
            </p>
        </>
    );
};

export default Post;
